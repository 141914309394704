import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { Container } from 'react-bootstrap'

export default class MainHeader extends Component {

    render() {
        return(
        
        <Jumbotron fluid > 
            <Container >
                <h1>
                    Bristol Song Recital Series
                </h1>
            </Container>
        </Jumbotron> 
        
        )
      }
}