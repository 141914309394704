
import './App.css';
import CarThing from './components/carthing.js';
import MainHeader from './components/mainHeader';
import Navspace from './components/navbar'

function App() {
  return (
    <div>
      <Navspace></Navspace>
      <MainHeader></MainHeader>
      <CarThing></CarThing>
    </div>

  );
}

export default App;
