import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

export default class Navspace extends Component {
    
    render() {
        return (
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="">Bristol Song Recitals</Navbar.Brand>
            </Navbar>
        )

    }

}