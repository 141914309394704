import React, { Component } from 'react';

class CarthingPanel extends Component {
    render () {
        return (
            <div className="Carthing_center carthing_card">
                <h2>{this.props.obj.title}</h2>
                <p>{this.props.obj.text}</p>
            </div>
        )
    }
}

export default CarthingPanel