import React, { Component } from 'react';
import axios from 'axios';
import CarthingPanel from './carthingPanel';

export default class CarThing extends Component {

  constructor(props) {
    super(props)
    this.state = { usersCollection: [] }
    
  }
  
  componentDidMount() {
    axios.get('http://localhost:4000/api/panels')
      .then(res =>  {
        this.setState({ usersCollection: res.data})
      })
    
  }

  doPanel() {
    return this.state.usersCollection.map((data, i) => {
      return ( 
        <div className="col-sm-4">
          <CarthingPanel obj={data} key={i} />
        </div>
      )
    })
  }

  render() {
    return(
    
      <div className="row carthing_wrap">
        {this.doPanel()}
      </div>
  
    )
  }

}
  